import { useState, useEffect } from 'react';

export const emailRegex = /\S+@\S+\.\S+/;

export const useStateWithValidation = (defaultValue, { regex }) => {
  const [value, setValue] = useState(defaultValue);
  const [valid, setValid] = useState(false);
  useEffect(() => {
    if (value && value.length) setValid(regex.test(value));
  }, [value]);

  return [value, setValue, valid];
};
