import classNames from 'classnames';
import React, { FC, InputHTMLAttributes, forwardRef } from 'react';

const noop = () => null;
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  label: string;
  leadingIcon?: any | null;
  trailingIcon?: any | null;
  inputClassName?: string | null;
  containerClassName?: string | null;
  onFocus?: () => void | null;
  autocomplete?: string;
}

// TODO: Embedded version (maybe not needed?)
// - disabled
// - helper text
// - floating labels
// - success
// - error

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      label,
      className,
      leadingIcon: LeadingIcon = null,
      trailingIcon: TrailingIcon = null,
      placeholder,
      disabled = false,
      inputClassName = null,
      containerClassName = null,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={classNames(' relative z-0', className)}>
        {!!label && (
          <label
            htmlFor={name}
            className="block text-12 font-secondary font-semibold"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}
        <div
          className={classNames(
            'mt-0.5 border border-grey-light focus-within:border-royal-blue-light',
            'rounded-sm relative group',
            containerClassName || '',
          )}
        >
          {LeadingIcon && (
            <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
              <LeadingIcon className="w-4 h-4 fill-[#B2B1B1]" />
            </div>
          )}
          <input
            ref={ref}
            type="text"
            name={name}
            id={name}
            className={classNames(
              'focus:ring-0 focus:border-0 block w-full border-0 ring-0',
              'text-16 font-secondary font-regular ',
              LeadingIcon && 'pl-7',
              TrailingIcon && 'pr-7',
              disabled && 'text-grey-dark',
              inputClassName || '',
            )}
            placeholder={placeholder}
            {...props}
          />

          {TrailingIcon && (
            <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
              <TrailingIcon className="w-4 h-4 fill-[#B2B1B1]" />
            </div>
          )}
        </div>
      </div>
    );
  },
);
Input.displayName = 'Input';
